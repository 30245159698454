// @flow
import { DataGrid, GridColumnVisibilityModel, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useIntl } from 'react-intl';
import type {
  TbAssetTelemetriesItem,
  TbLocationItem,
  TbTenantCounterItem,
  TelemetryAttribute
} from 'types/Thingsboard.types';
import { useEffect, useState } from 'react';
import { InputAdornment, Switch } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { isDefined, isEmpty, isNotEmpty, isNotEmptyList } from 'util/ObjectUtils';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TextField from '@mui/material/TextField';
import DataGridSettingsDrawer from 'components/drawer/DataGridSettingsDrawer';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import Tooltip from '@mui/material/Tooltip';
import {
  getDataGridColumnVisibility,
  storeDataGridColumnVisibility,
  SUBSCRIPTIONS_DATAGRID_COLUMN_VISIBILITY_V6,
  TENANT_DATAGRID_COLUMN_VISIBILITY_V6
} from 'api/service/LocalStorageService';
import {
  APPLICATION_PAGE,
  DATA_GRID_DIALOG_TYPE,
  DATA_GRID_TYPE,
  REFRESH_CACHE_TYPE_ENUM,
  WORLD_MAP_ELEMENTS
} from 'constants/GlobalConstants';
import { useTheme } from '@mui/styles';
import TenantDetailsDialog from 'components/dialog/manager/TenantDetailsDialog';
import { useDispatch, useSelector } from 'react-redux';
import { isAdministratorOrSupport } from 'store/selector/UserSelector';
import OdooTenantLinkDialog from 'components/dialog/odoo/OdooTenantLinkDialog';
import { getChargeBeeUrlSetting, getOdooUrlSetting, getSelectedLocale } from 'store/selector/ApplicationSelector';
import { MANAGER_TENANT_ATTRIBUTE_ENUM, TENANT_STATUS_ENUM } from 'constants/TenantConstants';
import TenantStatusToggle from 'components/common/TenantStatusToggle';
import CreateSubscriptionDialog from 'components/dialog/chargebee/CreateSubscriptionDialog';
import {
  createColumns,
  resolveChargeBeeData,
  resolveColumnVisibilityModel,
  resolveSubscriptionsDataGrid,
  resolveSubscriptionsStatisticsComponent,
  resolveTenantsStatisticsComponent
} from 'components/datagrid/helper/ManagerDataGridHelper';
import ShutOutDateDialog from 'components/dialog/manager/ShutOutDateDialog';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CreateNewTenantDialog from 'components/dialog/manager/CreateNewTenantDialog';
import ChargebeeSubscriptionLinkDialog from 'components/dialog/chargebee/ChargebeeSubscriptionLinkDialog';
import CommentDialog from 'components/dialog/manager/CommentDialog';
import WorldMapDialog from 'components/dialog/WorldMapDialog';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import GatewaysDialog from 'components/dialog/manager/GatewaysDialog';
import AlarmsChartDialog from 'components/dialog/manager/AlarmsChartDialog';
import { getOdooTenants } from 'api/service/OdooApiService';
import {
  getTbLocationSitesData,
  getTbManagerDevicesWithTelemetry,
  getTbManagerTenantsCounter,
  getTbManagerTenantsWithTelemetry
} from 'api/service/ThingsboardApiService';
import { SYSTEM_TYPE, TB_DEVICE_TYPE_ENUM } from 'constants/ThingsboardConstants';
import {
  getChargeBeeCustomers,
  getChargeBeeInvoices,
  getChargeBeeSubscriptions,
  getChargeBeeUnbilledCharges
} from 'api/service/ChargeBeeApiService';
import Typography from '@mui/material/Typography';
import TenantListDrawer from 'components/drawer/TenantListDrawer';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import OdooNoteDialog from 'components/dialog/manager/OdooNoteDialog';
import { refreshCache } from 'api/service/ApplicationApiService';
import { v4 as uuid } from 'uuid';
import { addAlert } from 'store/slice/ApplicationSlice';

type Props = {
  dataGridPage: string,
  preFilteredTenantId?: string
};

const ManagerDataGrid = ({ dataGridPage, preFilteredTenantId }: Props): React$Node => {
  const dispatch = useDispatch();
  const [odooTenants, setOdooTenants] = useState([]);
  const [chargeBeeCustomers, setChargeBeeCustomers] = useState([]);
  const [chargeBeeSubscriptions, setChargeBeeSubscriptions] = useState([]);
  const [chargeBeeInvoices, setChargeBeeInvoices] = useState([]);
  const [chargeBeeUnbilledCharges, setChargeBeeUnbilledCharges] = useState([]);

  const [tenantsTelemetry, setTenantsTelemetry] = useState([]);
  const [tenantsCounter, setTenantsCounter] = useState([]);
  const [locationSitesData, setLocationSitesData] = useState([]);
  const [gateways, setGateways] = useState([]);

  useEffect(() => {
    fetchTenantsWithTelemetries();
    fetchTenants();
    fetchOdooTenants();
    getTbLocationSitesData(dispatch).then((response) => setLocationSitesData(response));
    getTbManagerDevicesWithTelemetry(dispatch, TB_DEVICE_TYPE_ENUM.GATEWAY).then((response) =>
      setGateways(response ?? [])
    );

    fetchAllChargeBeeData();
  }, []);

  const fetchAllChargeBeeData = () => {
    getChargeBeeCustomers(dispatch).then((response) => setChargeBeeCustomers(response));
    fetchSubscriptions();
    getChargeBeeInvoices(dispatch).then((response) => setChargeBeeInvoices(response));
    fetchUnbilledCharges();
  };

  const fetchTenants = () => {
    getTbManagerTenantsCounter(dispatch).then((response) => setTenantsCounter(response));
  };

  const fetchTenantsWithTelemetries = () => {
    getTbManagerTenantsWithTelemetry(dispatch).then((response) => setTenantsTelemetry(response));
  };

  const fetchSubscriptions = () => {
    getChargeBeeSubscriptions(dispatch).then((response) => setChargeBeeSubscriptions(response));
  };
  const fetchUnbilledCharges = () => {
    getChargeBeeUnbilledCharges(dispatch).then((response) => setChargeBeeUnbilledCharges(response));
  };

  const fetchOdooTenants = () => {
    getOdooTenants(dispatch).then((response) => setOdooTenants(response));
  };

  const intl = useIntl();
  const theme = useTheme();
  const isAdminOrSupport = useSelector(isAdministratorOrSupport);
  const odooUrl = useSelector(getOdooUrlSetting);
  const chargeBeeUrl = useSelector(getChargeBeeUrlSetting);
  const selectedLocale = useSelector(getSelectedLocale);

  const [searchText, setSearchText] = useState('');
  const [dataGridItems, setDataGridItems] = useState([]);
  const [dataGridRows, setDataGridRows] = useState([]);

  const [tenantListDrawerOpen, setTenantListDrawerOpen] = useState(false);
  const [visibleColumnsDrawerOpen, setVisibleColumnsDrawerOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(
    dataGridPage === APPLICATION_PAGE.MANAGER_SUBSCRIPTIONS
      ? getDataGridColumnVisibility(SUBSCRIPTIONS_DATAGRID_COLUMN_VISIBILITY_V6)
      : getDataGridColumnVisibility(TENANT_DATAGRID_COLUMN_VISIBILITY_V6)
  );
  const [tenantStatusVisibility, setTenantStatusVisibility] = useState({
    [undefined]: true,
    [TENANT_STATUS_ENUM.DEVELOPMENT]: dataGridPage !== APPLICATION_PAGE.MANAGER_SUBSCRIPTIONS,
    [TENANT_STATUS_ENUM.ARCHIVED]: false,
    [TENANT_STATUS_ENUM.CANCELED]: false
  });
  const [tenantOnPremiseVisibility, setTenantOnPremiseVisibility] = useState(
    dataGridPage === APPLICATION_PAGE.MANAGER_TENANTS
  );
  const [sitesLocation, setSitesLocation] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({});
  const [dialogToOpen, setDialogToOpen] = useState(null);
  const [createTenantDialogOpen, setCreateTenantDialogOpen] = useState(false);

  const [tenantDetails, setTenantDetails] = useState(null);
  const [tenantInfo, setTenantInfo] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openActionMenu = (event: Event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeActionMenu = () => {
    setAnchorEl(null);
  };

  const closeVisibleColumnsDrawer = () => {
    setVisibleColumnsDrawerOpen(false);
    closeActionMenu();
  };

  const closeTenantListDrawer = () => {
    setTenantListDrawerOpen(false);
    closeActionMenu();
  };

  useEffect(() => {
    let tempItems = resolveItems();
    setDataGridItems(tempItems);

    let filteredItems = filterByTenantStatus(tempItems);
    filteredItems = filterBySystemType(filteredItems);

    if (isDefined(preFilteredTenantId)) {
      filteredItems = filteredItems?.filter((item) => item.assetDto?.id?.id === preFilteredTenantId);
      setSearchText(preFilteredTenantId);
    }
    const rows = resolveSubscriptionsDataGrid(filteredItems, chargeBeeUnbilledCharges);
    setDataGridRows(rows);
  }, [
    tenantsTelemetry,
    tenantsCounter,
    locationSitesData,
    gateways,
    odooTenants,
    chargeBeeCustomers,
    chargeBeeSubscriptions,
    chargeBeeInvoices,
    chargeBeeUnbilledCharges
  ]);

  useEffect(() => {
    let filteredItems = dataGridItems?.filter(
      (item: TbAssetTelemetriesItem) =>
        item.assetDto?.id?.id?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        item.assetDto?.description?.toString().toLowerCase().includes(searchText.toLowerCase()) ||
        item.assetDto?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.odooTenant?.customer?.toLowerCase().includes(searchText.toLowerCase()) ||
        item.matchedChargeBeeCustomers
          ?.map((customer) => customer.data.email)
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase()) ||
        item.matchedChargeBeeCustomers
          ?.map((customer) => customer.data.company)
          ?.toString()
          .toLowerCase()
          .includes(searchText.toLowerCase())
    );

    filteredItems = filterByTenantStatus(filteredItems);
    filteredItems = filterBySystemType(filteredItems);

    const rows = resolveSubscriptionsDataGrid(filteredItems, chargeBeeUnbilledCharges);
    setDataGridRows(rows);
  }, [searchText, JSON.stringify(tenantStatusVisibility), tenantOnPremiseVisibility]);

  useEffect(() => {
    const updatedVisibilityModel = resolveColumnVisibilityModel(visibleColumns, tenantStatusVisibility);

    const localStorageKey =
      dataGridPage === APPLICATION_PAGE.MANAGER_SUBSCRIPTIONS
        ? SUBSCRIPTIONS_DATAGRID_COLUMN_VISIBILITY_V6
        : TENANT_DATAGRID_COLUMN_VISIBILITY_V6;

    setColumnVisibilityModel(updatedVisibilityModel);
    storeDataGridColumnVisibility(localStorageKey, visibleColumns);
  }, [visibleColumns, JSON.stringify(tenantStatusVisibility)]);

  const resolveItems = () => {
    let tempItems = [];
    tenantsTelemetry?.forEach((item: TbAssetTelemetriesItem) => {
      const matchedTenantCounter = tenantsCounter?.find(
        (tenantCounter: TbTenantCounterItem) => tenantCounter.tenantMasterDto?.infoAssetId.id === item.assetDto.id.id
      );

      const matchedSitesForTenant = locationSitesData?.filter(
        (locationItem: TbLocationItem) => locationItem?.tenantId.id === item?.assetDto.id.id
      );

      const matchedGatewaysForTenant = gateways?.filter(
        (gateway: TbAssetTelemetriesItem) =>
          gateway.assetDto.tenantId.id === matchedTenantCounter?.tenantMasterDto?.id.id
      );

      let attributes = item.attributes;
      const odooTenantId = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.ODOO_TENANT
      )?.value;

      let odooTenant;
      if (isNotEmpty(odooTenantId)) {
        odooTenant = odooTenants?.find((odooTenant) => odooTenant.id === odooTenantId);
      }

      let { matchedChargeBeeCustomers, matchedChargeBeeSubscriptions, matchedChargeBeeInvoices } = resolveChargeBeeData(
        attributes,
        chargeBeeCustomers,
        chargeBeeSubscriptions,
        chargeBeeInvoices
      );

      tempItems.push({
        ...item,
        tenantCounter: matchedTenantCounter,
        tenantSites: matchedSitesForTenant,
        tenantGateways: matchedGatewaysForTenant,
        odooTenant: odooTenant,
        matchedChargeBeeCustomers: matchedChargeBeeCustomers,
        matchedChargeBeeSubscriptions: matchedChargeBeeSubscriptions,
        matchedChargeBeeInvoices: matchedChargeBeeInvoices
      });
    });

    return tempItems;
  };

  const filterByTenantStatus = (filteredItems: Array) => {
    return filteredItems?.filter((item: TbAssetTelemetriesItem) => {
      let attributes = item.attributes;
      const tenantStatus = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.TENANT_STATUS
      )?.value;

      return isEmpty(tenantStatus) ? tenantStatusVisibility[undefined] : tenantStatusVisibility[tenantStatus];
    });
  };

  const filterBySystemType = (filteredItems: Array) => {
    return filteredItems?.filter((item: TbAssetTelemetriesItem) => {
      let attributes = item.attributes;
      let isOnPremise = attributes.find(
        (attribute: TelemetryAttribute) => attribute.key === MANAGER_TENANT_ATTRIBUTE_ENUM.IS_ON_PREMISE
      )?.value;

      return tenantOnPremiseVisibility ? true : !isOnPremise;
    });
  };

  const handleSearchChange = (event: Event) => {
    setSearchText(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
  };

  const handleCloseDialog = () => {
    setTenantDetails(null);
    setTenantInfo(null);
    setDialogToOpen(null);
  };

  const handleOpenDialog = (tenantData, tenantInfo, dialogToOpen) => {
    setTenantDetails(tenantData);
    setTenantInfo(tenantInfo);
    setDialogToOpen(dialogToOpen);
  };

  const handleCloseTenantInfoDialog = () => {
    setTenantInfo(null);
    setDialogToOpen(null);
  };

  const handleOpenTenantInfoDialog = (tenantInfo, dialogToOpen) => {
    setTenantInfo(tenantInfo);
    setDialogToOpen(dialogToOpen);
  };

  const handleCloseCreateTenantDialog = () => {
    setCreateTenantDialogOpen(false);
  };

  const handleOpenCreateTenantDialog = () => {
    setCreateTenantDialogOpen(true);
  };

  const handleCloseWorldMapDialog = () => {
    setSitesLocation([]);
    closeActionMenu();
  };

  const handleRefreshChargeBeeCache = () => {
    refreshCache(dispatch, REFRESH_CACHE_TYPE_ENUM.CHARGEBEE_CACHE)
      .then(fetchAllChargeBeeData)
      .then(() => {
        const alert = {
          id: uuid(),
          severity: 'info',
          message: intl.formatMessage({ id: 'app.alert.info.cbDataRefreshed' })
        };
        dispatch(addAlert(alert));
      });
  };

  const setSitesLocationItems = () => {
    const tenantsSitesLocation = [];
    dataGridRows?.forEach((row) => {
      const sites = row.tenantSites;
      sites?.forEach((site) => {
        const onPremise = row.systemType === SYSTEM_TYPE.ON_PREMISE;
        const newLocationItem = {
          longitude: site.longitude,
          latitude: site.latitude,
          name: site.name,
          type: WORLD_MAP_ELEMENTS.SITE,
          onPremise: onPremise
        };
        tenantsSitesLocation.push(newLocationItem);
      });
    });
    setSitesLocation(tenantsSitesLocation);
  };

  const columns = createColumns(
    intl,
    handleOpenDialog,
    isAdminOrSupport,
    odooTenants,
    handleOpenTenantInfoDialog,
    odooUrl,
    chargeBeeUrl,
    selectedLocale
  );

  const StatisticsComponent =
    dataGridPage === APPLICATION_PAGE.MANAGER_SUBSCRIPTIONS
      ? resolveSubscriptionsStatisticsComponent(dataGridRows)
      : resolveTenantsStatisticsComponent(dataGridRows);

  return (
    <Box sx={{ height: '75vh', width: '100%' }}>
      {isNotEmptyList(sitesLocation) && (
        <WorldMapDialog open handleClose={handleCloseWorldMapDialog} locationItems={sitesLocation} />
      )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.TENANT_DETAILS && isDefined(tenantDetails) && (
        <TenantDetailsDialog
          open
          handleClose={handleCloseDialog}
          tenantMaster={tenantDetails}
          tenantInfo={tenantInfo}
          fetchTenantsWithTelemetries={fetchTenantsWithTelemetries}
          fetchTenants={fetchTenants}
          fetchOdooTenants={fetchOdooTenants}
          odooTenants={odooTenants}
        />
      )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.GATEWAYS_DIALOG && isDefined(tenantDetails) && (
        <GatewaysDialog open handleClose={handleCloseDialog} tenantMaster={tenantDetails} />
      )}
      {(dialogToOpen === DATA_GRID_DIALOG_TYPE.ACTIVE_ALARM_CHART_DIALOG ||
        dialogToOpen === DATA_GRID_DIALOG_TYPE.STALE_ALARM_CHART_DIALOG) &&
        isDefined(tenantDetails) && (
          <AlarmsChartDialog dialogType={dialogToOpen} open handleClose={handleCloseDialog} tenant={tenantDetails} />
        )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.ODOO_TENANT_LINK_DIALOG && isDefined(tenantInfo) && (
        <OdooTenantLinkDialog
          open
          handleClose={handleCloseTenantInfoDialog}
          odooTenants={odooTenants}
          tenantInfo={tenantInfo}
          fetchTenantsWithTelemetries={fetchTenantsWithTelemetries}
        />
      )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.CREATE_SUBSCRIPTION_DIALOG && isDefined(tenantInfo) && (
        <CreateSubscriptionDialog
          open
          handleClose={handleCloseTenantInfoDialog}
          chargeBeeCustomers={chargeBeeCustomers}
          tenantInfo={tenantInfo}
          fetchSubscriptions={fetchSubscriptions}
          fetchTenantsWithTelemetries={fetchTenantsWithTelemetries}
          fetchUnbilledCharges={fetchUnbilledCharges}
        />
      )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.SHUTOUT_DATE_DIALOG && isDefined(tenantDetails) && (
        <ShutOutDateDialog
          open
          handleClose={handleCloseTenantInfoDialog}
          tenantMaster={tenantDetails}
          fetchTenantsWithTelemetries={fetchTenantsWithTelemetries}
          fetchTenants={fetchTenants}
        />
      )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.CHARGEBEE_SUBSCRIPTION_LINK_DIALOG && isDefined(tenantInfo) && (
        <ChargebeeSubscriptionLinkDialog
          open
          handleClose={handleCloseTenantInfoDialog}
          cbSubscriptions={chargeBeeSubscriptions}
          cbCustomers={chargeBeeCustomers}
          tenantInfo={tenantInfo}
          fetchTenantsWithTelemetries={fetchTenantsWithTelemetries}
        />
      )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.COMMENT_DIALOG && isDefined(tenantInfo) && (
        <CommentDialog
          open
          handleClose={handleCloseTenantInfoDialog}
          tenantInfo={tenantInfo}
          fetchTenantsWithTelemetries={fetchTenantsWithTelemetries}
        />
      )}
      {dialogToOpen === DATA_GRID_DIALOG_TYPE.NOTE_DIALOG && isDefined(tenantInfo) && (
        <OdooNoteDialog
          open
          handleClose={handleCloseTenantInfoDialog}
          tenantInfo={tenantInfo}
          odooTenants={odooTenants}
          fetchOdooTenants={fetchOdooTenants}
        />
      )}
      {createTenantDialogOpen && (
        <CreateNewTenantDialog
          open={createTenantDialogOpen}
          handleClose={handleCloseCreateTenantDialog}
          fetchTenants={fetchTenants}
          fetchTenantsWithTelemetries={fetchTenantsWithTelemetries}
          odooTenants={odooTenants}
        />
      )}
      {visibleColumnsDrawerOpen && (
        <DataGridSettingsDrawer
          dataGridType={DATA_GRID_TYPE.MANAGER_DATA_GRID}
          open={visibleColumnsDrawerOpen}
          setOpen={setVisibleColumnsDrawerOpen}
          closeDrawer={closeVisibleColumnsDrawer}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
        />
      )}
      {tenantListDrawerOpen && (
        <TenantListDrawer
          open={tenantListDrawerOpen}
          closeDrawer={closeTenantListDrawer}
          odooTenants={odooTenants}
          tenants={tenantsTelemetry}
        />
      )}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            variant="outlined"
            size="small"
            margin="normal"
            fullWidth
            label={intl.formatMessage({ id: 'app.common.search' })}
            type={'text'}
            sx={{
              width: theme.spacing(29)
            }}
            InputProps={{
              style: {
                borderRadius: '8px'
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlinedIcon color="primary" />
                </InputAdornment>
              ),
              endAdornment: isNotEmpty(searchText) ? (
                <InputAdornment position="end">
                  <IconButton aria-label="search bar" edge="end" onClick={handleClearSearch}>
                    <CloseOutlinedIcon color="primary" />
                  </IconButton>
                </InputAdornment>
              ) : null
            }}
            value={searchText}
            onChange={handleSearchChange}
          />
          {StatisticsComponent}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Box sx={{ display: 'flex', mr: 1, alignItems: 'center' }}>
            <Switch
              size="small"
              checked={tenantOnPremiseVisibility}
              onChange={(event) => setTenantOnPremiseVisibility(event.target.checked)}
            />
            <Typography variant="body2" sx={{ ml: 0.5 }}>
              {intl.formatMessage({ id: 'app.datagrid.subscriptions.systemType.onPremise' })}
            </Typography>
          </Box>
          <TenantStatusToggle
            tenantStatusSettings={tenantStatusVisibility}
            setTenantStatusVisibility={setTenantStatusVisibility}
          />
          <Tooltip title={intl.formatMessage({ id: 'app.common.refreshData' })}>
            <IconButton size="small" onClick={handleRefreshChargeBeeCache}>
              <RefreshOutlinedIcon sx={{ width: '20px', height: '20px' }} />
            </IconButton>
          </Tooltip>
          {isAdminOrSupport && (
            <Tooltip title={intl.formatMessage({ id: 'app.datagrid.tenant.createNewTenant' })}>
              <IconButton size="small" onClick={handleOpenCreateTenantDialog}>
                <AddOutlinedIcon sx={{ width: '20px', height: '20px' }} />
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={openActionMenu}>
            <MoreVertOutlinedIcon fontSize="small" />
          </IconButton>
          <Menu
            open={open}
            onClose={closeActionMenu}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem onClick={setSitesLocationItems} sx={{ display: 'flex' }}>
              <LocationOnOutlinedIcon />
              <Typography sx={{ ml: 2 }}>
                {intl.formatMessage({ id: 'app.datagrid.location.sites.tooltip' })}
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => setVisibleColumnsDrawerOpen(true)}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <SettingsOutlinedIcon />
              <Typography sx={{ ml: 2 }}>
                {intl.formatMessage({ id: 'app.datagrid.drawer.visible.columns.title' })}
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => setTenantListDrawerOpen(true)}>
              <ListAltOutlinedIcon />
              <Typography sx={{ ml: 2 }}>{intl.formatMessage({ id: 'app.datagrid.drawer.tenant.list' })}</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <DataGrid
        getRowHeight={() => 'auto'}
        rows={dataGridRows}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100
            }
          }
        }}
        pageSizeOptions={[100]}
        slots={{
          toolbar: GridToolbar
        }}
        density={'compact'}
        disableColumnSelector
        disableColumnFilter
      />
    </Box>
  );
};

export default ManagerDataGrid;
