const withBasePath = (path) => `/api/${path}`;

// Authentication endpoint
export const LOGIN_API = withBasePath('authenticate');

//Application endpoints
export const AGROLOG_ANALYTICS_BASIC_AUTHORIZATION_CODE = withBasePath('agrolog-analytics/authorization-code');
export const REFRESH_CACHE = withBasePath('agrolog/refresh-cache');

// Public endpoints without authentication
export const VALIDATE_CREDENTIALS = withBasePath('public/validate-credentials');
export const REQUEST_PASSWORD_RESET_API = withBasePath('public/forgot-password');
export const PASSWORD_RESET_API = withBasePath('public/reset-password');
export const VERIFY_TOKEN_API = withBasePath('public/verify-token');
export const REGISTER_USER = withBasePath('public/register-user');
export const ACTIVATE_USER = withBasePath('public/activate-user');
export const INVITE_USERS = withBasePath('public/invite-users');
export const VALIDATE_EMAIL = withBasePath('public/validate-email');
export const APPLICATION_SETTINGS = withBasePath('public/application-settings');
export const TB_LOCATION_DATA = withBasePath('public/tb/location-data');
export const TB_LOCATION_SITES_DATA = withBasePath('public/tb/location-data/sites');

// User endpoints
export const USER_LOGOUT_API = withBasePath('user/logout');
export const USER_DETAILS_API = withBasePath('user/details');
export const USER_2FA_TOTP_URL_API = withBasePath('user/2fa-totp-url');

// Admin endpoints
export const ADMIN_USERS = withBasePath('/admin/users');
export const ADMIN_USERS_ACTIVATION = withBasePath('/admin/users/activation');
export const ADMIN_REFRESH_LONG_TERM_CACHE = withBasePath('/admin/refresh-long-term-cache');
export const ADMIN_REFRESH_SHORT_TERM_CACHE = withBasePath('/admin/refresh-short-term-cache');

// Thingsboard related endpoints
// Manager API's
export const TB_MANAGER_RPC = withBasePath('tb/manager/rpc');
export const TB_MANAGER_SITES_DATA = withBasePath('tb/manager/sites');
export const TB_MANAGER_TENANTS_ASSET_INFO = withBasePath('tb/manager/tenants/asset-info');
export const TB_MANAGER_DEVICES_DATA = withBasePath('tb/manager/tenants/devices');
export const TB_MANAGER_ASSETS_DATA = withBasePath('tb/manager/tenants/assets');
export const TB_MANAGER_ASSETS_WITH_RELATIONS_DATA = withBasePath('tb/manager/tenants/assets/relations');
export const TB_MANAGER_ASSETS_DEVICES_WITH_TELEMETRY = withBasePath('tb/manager/telemetries/assets-and-devices');
export const TB_MANAGER_DEVICES_WITH_TELEMETRY = withBasePath('tb/manager/telemetries/devices');
export const TB_MANAGER_TENANTS_WITH_TELEMETRY = withBasePath('tb/manager/telemetries/tenants');
export const TB_MANAGER_TENANTS_COUNTER = withBasePath('tb/manager/tenants/counter');
export const TB_MANAGER_TENANTS_MASTER = withBasePath('tb/manager/tenants/master');

// Farmer API's
export const TB_FARMER_CUSTOMERS = withBasePath('tb/farmer/customers');
export const TB_FARMER_CUSTOMERS_RELATIONS_DATA = withBasePath('tb/farmer/customers/relations');
export const TB_FARMER_CUSTOMERS_DATA = withBasePath('tb/farmer/customers/data');
export const TB_FARMER_CUSTOMER_DEVICE_UNASSIGN = withBasePath('tb/farmer/customer/device/unassign');
export const TB_FARMER_CUSTOMER_DEVICE_ASSIGN = withBasePath('tb/farmer/customer/device/assign');
export const TB_FARMER_DEVICES_DATA = withBasePath('tb/farmer/devices');
export const TB_FARMER_DEVICES_DATA_BY_TYPE = withBasePath('tb/farmer/devices/type');
export const TB_FARMER_ASSETS_DATA = withBasePath('tb/farmer/assets');
export const TB_FARMER_ASSETS_FILTERED = withBasePath('tb/farmer/assets-filtered');
export const TB_FARMER_SPEARS_TELEMETRY_DATA = withBasePath('tb/farmer/spears/telemetry');
export const TB_FARMER_DEVICES_TELEMETRY_DATA = withBasePath('tb/farmer/devices/telemetry');
export const TB_FARMER_ALARMS = withBasePath('tb/farmer/alarms-filtered');
export const TB_FARMER_RELATIONS_DELETE_CUSTOMER_TO_DEVICE = withBasePath('tb/farmer/relation/customer-device');
export const TB_FARMER_USER_ACCESS_TOKEN = withBasePath('tb/farmer/access-token');

// Farmer Telemetry API's
export const TB_FARMER_TELEMETRY_ATTRIBUTE_UPDATE = withBasePath('tb/farmer/telemetry/attribute');

// Manager Telemetry API's
export const TB_MANAGER_TELEMETRY_ENTITY = withBasePath('tb/manager/telemetry');
export const TB_MANAGER_TELEMETRY_ATTRIBUTE_UPDATE = withBasePath('tb/manager/telemetry/attribute');

//Health API's
export const MANAGER_TELEMETRY_STATUS_CHECK = withBasePath('manager/health/telemetries');
export const FARMER_TELEMETRY_STATUS_CHECK = withBasePath('farmer/health/telemetries');

//Odoo API's
export const ODOO_TENANTS = withBasePath('odoo/tenants');
export const ODOO_SALES_ORDERS = withBasePath('odoo/sales-orders');
export const ODOO_NOTE = withBasePath('odoo/note');

//ChargeBee API's
export const CHARGEBEE_CUSTOMERS = withBasePath('chargebee/customers');
export const CHARGEBEE_SUBSCRIPTIONS = withBasePath('chargebee/subscriptions');
export const CHARGEBEE_INVOICES = withBasePath('chargebee/invoices');
export const CHARGEBEE_UNBILLED_CHARGES = withBasePath('chargebee/unbilled-charges');
export const CHARGEBEE_SUBSCRIPTION = withBasePath('chargebee/subscription');
