// @flow

import { blueGrey } from '@mui/material/colors';
import { WORLD_MAP_ELEMENTS } from 'constants/GlobalConstants';
import { INVOICE_STATUS_ENUM, SUBSCRIPTION_STATUS_ENUM } from 'constants/ChargeBeeConstants';
import { SERVICE_ENUM } from 'constants/CommonConstants';
import { TENANT_STATUS_ENUM } from 'constants/TenantConstants';

const NotDefinedValueColorMap = {
  [null]: blueGrey[200],
  [undefined]: blueGrey[200]
};

export const WorldMapElementsColorMap = {
  ...NotDefinedValueColorMap,
  [WORLD_MAP_ELEMENTS.SITE]: '#ed6d05',
  [WORLD_MAP_ELEMENTS.SITE_ON_PREMISE]: '#bb05ed',
  [WORLD_MAP_ELEMENTS.SENSOR_SPEAR]: '#00b5d8',
  [WORLD_MAP_ELEMENTS.BUILDING]: '#11ce36'
};

export const SubscriptionStatusColorMap = {
  ...NotDefinedValueColorMap,
  [SUBSCRIPTION_STATUS_ENUM.ACTIVE]: '#306e24',
  [SUBSCRIPTION_STATUS_ENUM.FUTURE]: '#6200ea',
  [SUBSCRIPTION_STATUS_ENUM.CANCELED]: '#fff',
  [SUBSCRIPTION_STATUS_ENUM.NON_RENEWING]: '#aa2c39'
};

export const SubscriptionStatusBorderColorMap = {
  ...NotDefinedValueColorMap,
  [SUBSCRIPTION_STATUS_ENUM.ACTIVE]: '#96d48a',
  [SUBSCRIPTION_STATUS_ENUM.FUTURE]: '#be99f2',
  [SUBSCRIPTION_STATUS_ENUM.CANCELED]: '#de3618',
  [SUBSCRIPTION_STATUS_ENUM.NON_RENEWING]: '#f4a190'
};

export const SubscriptionStatusBGColorMap = {
  ...NotDefinedValueColorMap,
  [SUBSCRIPTION_STATUS_ENUM.ACTIVE]: '#e6f5e4',
  [SUBSCRIPTION_STATUS_ENUM.FUTURE]: '#f3ecff',
  [SUBSCRIPTION_STATUS_ENUM.CANCELED]: '#de3618',
  [SUBSCRIPTION_STATUS_ENUM.NON_RENEWING]: '#fce8e5'
};

export const InvoiceStatusColorMap = {
  ...NotDefinedValueColorMap,
  [INVOICE_STATUS_ENUM.PAID]: '#306e24',
  [INVOICE_STATUS_ENUM.NOT_PAID]: '#aa2c39',
  [INVOICE_STATUS_ENUM.PAYMENT_DUE]: '#aa2c39'
};

export const InvoiceStatusBorderColorMap = {
  ...NotDefinedValueColorMap,
  [INVOICE_STATUS_ENUM.PAID]: '#96d48a',
  [INVOICE_STATUS_ENUM.NOT_PAID]: '#f4a190',
  [INVOICE_STATUS_ENUM.PAYMENT_DUE]: '#f4a190'
};

export const InvoiceStatusBGColorMap = {
  ...NotDefinedValueColorMap,
  [INVOICE_STATUS_ENUM.PAID]: '#e6f5e4',
  [INVOICE_STATUS_ENUM.NOT_PAID]: '#fce8e5',
  [INVOICE_STATUS_ENUM.PAYMENT_DUE]: '#fff'
};

export const ServiceColorMapper = {
  ...NotDefinedValueColorMap,
  [SERVICE_ENUM.THINGSBOARD]: '#03BCDA',
  [SERVICE_ENUM.CHARGEBEE]: '#f30',
  [SERVICE_ENUM.ODOO]: '#714B67'
};

export const TenantStatusColorMapper = {
  ...NotDefinedValueColorMap,
  [TENANT_STATUS_ENUM.DEVELOPMENT]: '#fcdf50',
  [TENANT_STATUS_ENUM.ARCHIVED]: '#3ed515',
  [TENANT_STATUS_ENUM.CANCELED]: '#d90921'
};
