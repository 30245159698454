export const TB_LOCATION_ITEM_TYPE_ENUM = {
  SITE: 'SITE',
  SITE_ON_PREMISE: 'SITE_ON_PREMISE',
  SENSOR_SPEAR: 'SENSOR_SPEAR',
  BUILDING: 'BUILDING'
};

export const TB_DEVICE_TYPE_ENUM = {
  TEMPERATURE_SENSOR_LINES: 'TEMPERATURE_SENSOR_LINES',
  MOISTURE_SENSOR_LINES: 'MOISTURE_SENSOR_LINES',
  LEVEL_INDICATOR: 'LEVEL_INDICATOR',
  HEAD_SPACE_SENSOR: 'HEAD_SPACE_SENSOR',
  WEATHER_STATION: 'WEATHER_STATION',
  GATEWAY: 'GATEWAY',
  SMS: 'SMS',
  MODBUS: 'MODBUS',
  SENSOR_SPEAR: 'SENSOR_SPEAR',
  SUPER_PRO: 'SUPER_PRO'
};

export const TB_DEVICE_SENSOR_TYPE_ENUM = {
  TEMPERATURE_SENSOR: 'TEMPERATURE_SENSOR',
  MOISTURE_SENSOR: 'MOISTURE_SENSOR',
  LEVEL_INDICATOR: 'LEVEL_INDICATOR',
  HEAD_SPACE_SENSOR: 'HEAD_SPACE_SENSOR',
  CO2_SENSOR: 'CO2_SENSOR'
};

export const TB_ALARM_COLUMNS = {
  ACTIVE_ALARMS: 'ACTIVE_ALARMS',
  ACTIVE_ALARM_TYPES: 'ACTIVE_ALARM_TYPES',
  STALE_ALARMS: 'STALE_ALARMS',
  STALE_ALARM_TYPES: 'STALE_ALARM_TYPES'
};

export const TB_DEVICE_ASSET_TYPE_ENUM = {
  SITE: 'SITE',
  SILO: 'SILO',
  SILO_GROUP: 'SILO_GROUP',
  WEATHER_STATION: 'WEATHER_STATION',
  AERATION: 'AERATION'
};

export const TB_ASSET_TYPE_ENUM = {
  SITE_INFO: 'site-info',
  TENANT_INFO: 'tenant-info'
};

export const TB_FARMER_DEVICE_TYPE_VALUE_ENUM = {
  SENSOR_SPEAR: 'cellular_sensor_spear',
  SUPER_PRO: 'super_pro',
  SUPER_POINT: 'super_point',
  SUPER_COMBI: 'super_combi',
  C_PRO_V1: 'c_pro',
  C_PRO_V2: 'c-pro',
  FARM_PRO: 'farm_pro',
  FARM_POINT: 'farm_point'
};

export const TB_ASSETS_TYPE_VALUE_ENUM = {
  BUILDING: 'building'
};

export const GRAIN_ANALYZERS = [
  TB_FARMER_DEVICE_TYPE_VALUE_ENUM.C_PRO_V1,
  TB_FARMER_DEVICE_TYPE_VALUE_ENUM.C_PRO_V2,
  TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SUPER_PRO,
  TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SUPER_COMBI,
  TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SUPER_POINT,
  TB_FARMER_DEVICE_TYPE_VALUE_ENUM.FARM_PRO,
  TB_FARMER_DEVICE_TYPE_VALUE_ENUM.FARM_POINT
];

export const TB_FARMER_ALARMS_TYPE_ENUM = {
  NO_TELEMETRY_ALARM: 'NO_TELEMETRY_ALARM',
  BATTERY_LEVEL_ALARM_MIN: 'BATTERY_LEVEL_ALARM_MIN',
  SAMPLE_TEMPERATURE_HIGH_ALARM: 'SAMPLE_TEMPERATURE_HIGH_ALARM',
  SAMPLE_TEMPERATURE_LOW_ALARM: 'SAMPLE_TEMPERATURE_LOW_ALARM',
  AMBIENT_TEMPERATURE_HIGH_ALARM: 'AMBIENT_TEMPERATURE_HIGH_ALARM',
  AMBIENT_TEMPERATURE_LOW_ALARM: 'AMBIENT_TEMPERATURE_LOW_ALARM',
  SAMPLE_EMC_HIGH_ALARM: 'SAMPLE_EMC_HIGH_ALARM',
  SAMPLE_EMC_LOW_ALARM: 'SAMPLE_EMC_LOW_ALARM',
  AMBIENT_EMC_HIGH_ALARM: 'AMBIENT_EMC_HIGH_ALARM',
  AMBIENT_EMC_LOW_ALARM: 'AMBIENT_EMC_LOW_ALARM',
  SAMPLE_HUMIDITY_HIGH_ALARM: 'SAMPLE_HUMIDITY_HIGH_ALARM',
  SAMPLE_HUMIDITY_LOW_ALARM: 'SAMPLE_HUMIDITY_LOW_ALARM',
  SAMPLE_TEMPERATURE_MIN_ALARM: 'SAMPLE_TEMPERATURE_MIN_ALARM'
};

export const TB_ALARMS_SEVERITY_ENUM = {
  CRITICAL: 'CRITICAL',
  MAJOR: 'MAJOR',
  MINOR: 'MINOR',
  WARNING: 'WARNING',
  INDETERMINATE: 'INDETERMINATE'
};

export const TB_ALARMS_STATUS_ENUM = {
  ACTIVE_UNACK: 'ACTIVE_UNACK',
  ACTIVE_ACK: 'ACTIVE_ACK',
  CLEARED_UNACK: 'CLEARED_UNACK',
  CLEARED_ACK: 'CLEARED_ACK'
};

export const TB_ENTITY_TYPE_ENUM = {
  TENANT: 'TENANT',
  CUSTOMER: 'CUSTOMER',
  USER: 'USER',
  DASHBOARD: 'DASHBOARD',
  ASSET: 'ASSET',
  DEVICE: 'DEVICE',
  ALARM: 'ALARM',
  RULE_CHAIN: 'RULE_CHAIN',
  RULE_NODE: 'RULE_NODE',
  ENTITY_VIEW: 'ENTITY_VIEW',
  WIDGETS_BUNDLE: 'WIDGETS_BUNDLE',
  WIDGET_TYPE: 'WIDGET_TYPE'
};

export const SYSTEM_TYPE = {
  CLOUD: 'CLOUD',
  ON_PREMISE: 'ON_PREMISE'
};
