// @flow

import {
  SYSTEM_TYPE,
  TB_ALARM_COLUMNS,
  TB_DEVICE_ASSET_TYPE_ENUM,
  TB_DEVICE_SENSOR_TYPE_ENUM,
  TB_DEVICE_TYPE_ENUM,
  TB_FARMER_DEVICE_TYPE_VALUE_ENUM
} from 'constants/ThingsboardConstants';
import { WORLD_MAP_ELEMENTS } from 'constants/GlobalConstants';
import { DEVICE_SUBSCRIPTION_ENUM, FARMER_DEVICE_TAB_NAVIGATION_ENUM } from 'constants/DeviceConstants';
import {
  CUSTOMERS_DATAGRID_COLUMNS,
  DEVICES_DATAGRID_COLUMNS,
  GATEWAY_DATAGRID_COLUMNS,
  MANAGER_DATAGRID_COLUMNS
} from 'constants/DataGridConstants';
import { TENANT_STATUS_ENUM } from 'constants/TenantConstants';

export const NotDefinedValueTranslationId = {
  [null]: 'app.common.not.available',
  [undefined]: 'app.common.not.available'
};

export const FarmerDeviceTranslationId = {
  ...NotDefinedValueTranslationId,
  [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SENSOR_SPEAR]: 'app.device.farmerApp.sensorSpear',
  [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SUPER_PRO]: 'app.device.farmerApp.superPro',
  [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SUPER_POINT]: 'app.device.farmerApp.superPoint',
  [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.SUPER_COMBI]: 'app.device.farmerApp.superCombi',
  [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.C_PRO_V1]: 'app.device.farmerApp.cPro',
  [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.C_PRO_V2]: 'app.device.farmerApp.cPro',
  [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.FARM_PRO]: 'app.device.farmerApp.farmPro',
  [TB_FARMER_DEVICE_TYPE_VALUE_ENUM.FARM_POINT]: 'app.device.farmerApp.farmPoint'
};

export const WorldMapElementsTranslationId = {
  ...NotDefinedValueTranslationId,
  [WORLD_MAP_ELEMENTS.SITE]: 'app.general.overview.sites',
  [WORLD_MAP_ELEMENTS.SITE_ON_PREMISE]: 'app.general.overview.sites.onPremise',
  [WORLD_MAP_ELEMENTS.SENSOR_SPEAR]: 'app.general.overview.spears',
  [WORLD_MAP_ELEMENTS.BUILDING]: 'app.general.overview.buildings'
};

export const DeviceSubscriptionTranslationId = {
  ...NotDefinedValueTranslationId,
  [DEVICE_SUBSCRIPTION_ENUM.NEVER]: 'app.datagrid.subscription.never',
  [DEVICE_SUBSCRIPTION_ENUM.EXPIRED]: 'app.datagrid.subscription.expired'
};

export const FarmerDeviceTabNavigationTranslationId = {
  ...NotDefinedValueTranslationId,
  [FARMER_DEVICE_TAB_NAVIGATION_ENUM.SENSOR_SPEAR]: 'app.device.farmerApp.sensorSpear',
  [FARMER_DEVICE_TAB_NAVIGATION_ENUM.GRAIN_ANALYZERS]: 'app.device.farmerApp.grainAnalyzers'
};

export const GatewayDataGridColumnTranslationId = {
  ...NotDefinedValueTranslationId,
  [GATEWAY_DATAGRID_COLUMNS.STATUS]: 'app.datagrid.gateways.status',
  [GATEWAY_DATAGRID_COLUMNS.SSH]: 'app.datagrid.gateways.remoteSession',
  [GATEWAY_DATAGRID_COLUMNS.VERSION]: 'app.datagrid.gateways.version',
  [GATEWAY_DATAGRID_COLUMNS.IP]: 'app.datagrid.gateways.ip',
  [GATEWAY_DATAGRID_COLUMNS.SUBSYS_CONFIG]: 'app.datagrid.gateways.subsysConfig'
};

export const CustomersDataGridColumnTranslationId = {
  ...NotDefinedValueTranslationId,
  [CUSTOMERS_DATAGRID_COLUMNS.SPEARS]: 'app.datagrid.devices.spears',
  [CUSTOMERS_DATAGRID_COLUMNS.GRAIN_ANALYZERS]: 'app.datagrid.devices.grainAnalyzers',
  [CUSTOMERS_DATAGRID_COLUMNS.FREE_DEVICES]: 'app.datagrid.freeDevices',
  [CUSTOMERS_DATAGRID_COLUMNS.BUILDINGS]: 'app.datagrid.buildings',
  [CUSTOMERS_DATAGRID_COLUMNS.USERS]: 'app.datagrid.users',
  [CUSTOMERS_DATAGRID_COLUMNS.ALARMS]: 'app.datagrid.alarms',
  [CUSTOMERS_DATAGRID_COLUMNS.SUBSCRIPTIONS]: 'app.datagrid.subscriptions',
  [CUSTOMERS_DATAGRID_COLUMNS.COUNTRY]: 'app.datagrid.country',
  [CUSTOMERS_DATAGRID_COLUMNS.PHONE]: 'app.datagrid.phone'
};

export const DevicesDataGridColumnTranslationId = {
  ...NotDefinedValueTranslationId,
  [DEVICES_DATAGRID_COLUMNS.CLAIMED]: 'app.datagrid.claimed',
  [DEVICES_DATAGRID_COLUMNS.IS_FREE]: 'app.datagrid.isFree',
  [DEVICES_DATAGRID_COLUMNS.PREPAID_YEARS]: 'app.datagrid.prepaidYears',
  [DEVICES_DATAGRID_COLUMNS.SUBSCRIPTION_END]: 'app.datagrid.periodEnd',
  [DEVICES_DATAGRID_COLUMNS.ORDER_NUMBER]: 'app.datagrid.devices.orderNumber',
  [DEVICES_DATAGRID_COLUMNS.DISTRIBUTOR]: 'app.datagrid.devices.distributor',
  [DEVICES_DATAGRID_COLUMNS.LATEST_SAMPLE]: 'app.datagrid.devices.latestSample',
  [DEVICES_DATAGRID_COLUMNS.TEMPERATURE_1]: 'app.datagrid.devices.temperature.sensor1',
  [DEVICES_DATAGRID_COLUMNS.TEMPERATURE_2]: 'app.datagrid.devices.temperature.sensor2',
  [DEVICES_DATAGRID_COLUMNS.TEMPERATURE_AMBIENT]: 'app.datagrid.devices.temperature.ambient',
  [DEVICES_DATAGRID_COLUMNS.HUMIDITY_1]: 'app.datagrid.devices.humidity.sensor1',
  [DEVICES_DATAGRID_COLUMNS.HUMIDITY_AMBIENT]: 'app.datagrid.devices.humidity.ambient',
  [DEVICES_DATAGRID_COLUMNS.CROP_TYPE]: 'app.datagrid.devices.emc.cropType',
  [DEVICES_DATAGRID_COLUMNS.CROP_SENSOR_1]: 'app.datagrid.devices.emc.sensor1',
  [DEVICES_DATAGRID_COLUMNS.CROP_AMBIENT]: 'app.datagrid.devices.emc.ambient',
  [DEVICES_DATAGRID_COLUMNS.LONGITUDE]: 'app.datagrid.longitude',
  [DEVICES_DATAGRID_COLUMNS.LATITUDE]: 'app.datagrid.latitude',
  [DEVICES_DATAGRID_COLUMNS.SIM_LONGITUDE]: 'app.datagrid.simLongitude',
  [DEVICES_DATAGRID_COLUMNS.SIM_LATITUDE]: 'app.datagrid.simLatitude'
};

export const ManagerDataGridColumnTranslationId = {
  ...NotDefinedValueTranslationId,
  [MANAGER_DATAGRID_COLUMNS.SITES_DETAILS]: 'app.datagrid.sites.details',
  [MANAGER_DATAGRID_COLUMNS.GATEWAY_DETAILS]: 'app.datagrid.gateways',
  [MANAGER_DATAGRID_COLUMNS.LAST_GATEWAY_ACTIVITY]: 'app.datagrid.lastGatewayActivity',
  [MANAGER_DATAGRID_COLUMNS.SYSTEM_TYPE]: 'app.datagrid.subscriptions.systemType',
  [MANAGER_DATAGRID_COLUMNS.COMMENT]: 'app.datagrid.subscription.comment',
  [MANAGER_DATAGRID_COLUMNS.ODOO_CUSTOMER]: 'app.datagrid.subscriptions.customer',
  [MANAGER_DATAGRID_COLUMNS.ODOO_TENANT]: 'app.datagrid.subscriptions.odooLink',
  [MANAGER_DATAGRID_COLUMNS.ODOO_DESCRIPTION]: 'app.datagrid.subscriptions.odooDescription',
  [MANAGER_DATAGRID_COLUMNS.DEVELOPMENT]: 'app.datagrid.subscriptions.tna.development',
  [MANAGER_DATAGRID_COLUMNS.ARCHIVED]: 'app.datagrid.subscriptions.tna.archived',
  [MANAGER_DATAGRID_COLUMNS.CANCELED]: 'app.datagrid.subscriptions.tna.canceled',
  [MANAGER_DATAGRID_COLUMNS.CHARGEBEE_CUSTOMER]: 'app.datagrid.subscriptions.subscription.customer',
  [MANAGER_DATAGRID_COLUMNS.CHARGEBEE_SUBSCRIPTION]: 'app.datagrid.subscriptions.subscription',
  [MANAGER_DATAGRID_COLUMNS.SUBSCRIPTION_STATUS]: 'app.datagrid.subscriptions.subStatus',
  [MANAGER_DATAGRID_COLUMNS.UNBILLED_CHARGES]: 'app.datagrid.subscriptions.unbilledCharges',
  [MANAGER_DATAGRID_COLUMNS.INVOICE_STATUS]: 'app.datagrid.subscriptions.invoiceStatus',
  [MANAGER_DATAGRID_COLUMNS.BILLING_PERIOD]: 'app.datagrid.subscriptions.billingPeriod',
  [MANAGER_DATAGRID_COLUMNS.SHUTOUT_DATE]: 'app.datagrid.subscriptions.shutOutDate',
  [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_CB]: 'app.datagrid.subscriptions.tempSensorsCB',
  [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_TB]: 'app.datagrid.subscriptions.tempSensorsTB',
  [MANAGER_DATAGRID_COLUMNS.TEMP_SENSORS_ODOO]: 'app.datagrid.subscriptions.tempSensorsOdoo',
  [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_CB]: 'app.datagrid.subscriptions.moistSensorsCB',
  [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_TB]: 'app.datagrid.subscriptions.moistSensorsTB',
  [MANAGER_DATAGRID_COLUMNS.MOISTURE_SENSORS_ODOO]: 'app.datagrid.subscriptions.moistSensorsOdoo',
  [TB_DEVICE_ASSET_TYPE_ENUM.SITE]: 'app.datagrid.assetsCount.sites',
  [TB_DEVICE_ASSET_TYPE_ENUM.SILO_GROUP]: 'app.datagrid.assetsCount.siloGroups',
  [TB_DEVICE_ASSET_TYPE_ENUM.SILO]: 'app.datagrid.assetsCount.silos',
  [TB_DEVICE_TYPE_ENUM.TEMPERATURE_SENSOR_LINES]: 'app.datagrid.devicesCount.temperatureLines',
  [TB_DEVICE_TYPE_ENUM.MOISTURE_SENSOR_LINES]: 'app.datagrid.devicesCount.moistureLines',
  [TB_DEVICE_TYPE_ENUM.HEAD_SPACE_SENSOR]: 'app.datagrid.devicesCount.headspace',
  [TB_DEVICE_TYPE_ENUM.LEVEL_INDICATOR]: 'app.datagrid.devicesCount.levelIndicator',
  [TB_DEVICE_TYPE_ENUM.WEATHER_STATION]: 'app.datagrid.devicesCount.weatherStations',
  [TB_DEVICE_TYPE_ENUM.SMS]: 'app.datagrid.devicesCount.sms',
  [TB_DEVICE_TYPE_ENUM.MODBUS]: 'app.datagrid.devicesCount.modbus',
  [TB_DEVICE_TYPE_ENUM.GATEWAY]: 'app.datagrid.devicesCount.gateways',
  [TB_DEVICE_ASSET_TYPE_ENUM.AERATION]: 'app.datagrid.assetsCount.aerations',
  [TB_DEVICE_SENSOR_TYPE_ENUM.TEMPERATURE_SENSOR]: 'app.datagrid.sensorsCount.temperature',
  [TB_DEVICE_SENSOR_TYPE_ENUM.MOISTURE_SENSOR]: 'app.datagrid.sensorsCount.moisture',
  [TB_DEVICE_SENSOR_TYPE_ENUM.HEAD_SPACE_SENSOR]: 'app.datagrid.sensorsCount.headspace',
  [TB_DEVICE_SENSOR_TYPE_ENUM.CO2_SENSOR]: 'app.datagrid.sensorsCount.co2',
  [TB_DEVICE_SENSOR_TYPE_ENUM.LEVEL_INDICATOR]: 'app.datagrid.sensorsCount.levelIndicator',
  [TB_ALARM_COLUMNS.ACTIVE_ALARMS]: 'app.datagrid.alarms.activeCount.total',
  [TB_ALARM_COLUMNS.STALE_ALARMS]: 'app.datagrid.alarms.staleCount.total',
  [TB_ALARM_COLUMNS.ACTIVE_ALARM_TYPES]: 'app.datagrid.alarms.active',
  [TB_ALARM_COLUMNS.STALE_ALARM_TYPES]: 'app.datagrid.alarms.stale'
};

export const TelemetryHealthStatusTranslationId = {
  ...NotDefinedValueTranslationId,
  [true]: 'app.service.thingsboard.telemetriesHealth.ok',
  [false]: 'app.service.thingsboard.telemetriesHealth.notOk'
};

export const TenantStatusTranslationId = {
  ...NotDefinedValueTranslationId,
  [TENANT_STATUS_ENUM.DEVELOPMENT]: 'app.datagrid.subscriptions.tenant.development',
  [TENANT_STATUS_ENUM.ARCHIVED]: 'app.datagrid.subscriptions.tenant.archived',
  [TENANT_STATUS_ENUM.CANCELED]: 'app.datagrid.subscriptions.tenant.canceled'
};

export const SystemTypeTranslationId = {
  ...NotDefinedValueTranslationId,
  [SYSTEM_TYPE.ON_PREMISE]: 'app.datagrid.subscriptions.systemType.onPremise',
  [SYSTEM_TYPE.CLOUD]: 'app.datagrid.subscriptions.systemType.cloud'
};
