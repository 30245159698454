// @flow

import { isNotEmpty } from 'util/ObjectUtils';

export const copyToClipboard = (textToCopy: string) => {
  const clipBoard = navigator.clipboard;
  clipBoard.writeText(textToCopy).then(() => {
    console.debug('Copied to clipboard');
  });
};

export const AUTHENTICATION_ACTION = {
  ALLOW_AUTH: 'ALLOW_AUTH',
  ALLOW_AUTH_WITH_SMS: 'ALLOW_AUTH_WITH_SMS'
};

export const resolveCountryFromCode = (countryCode: string, selectedLocale: string) => {
  let regionNames = new Intl.DisplayNames([selectedLocale], { type: 'region' });
  return isNotEmpty(countryCode) ? regionNames.of(countryCode) : '';
};
