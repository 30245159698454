export const DEVICE_SUBSCRIPTION_ENUM = {
  NEVER: 'NEVER',
  EXPIRED: 'EXPIRED'
};

export const FARMER_DEVICE_ATTRIBUTE_ENUM = {
  IS_FREE: 'is_free',
  CLAIM_TS: 'claim_ts',
  PRE_PAID_YEARS: 'pre_paid_years',
  PERIOD_END: 'period_end',
  ORDER_NUMBER: 'order_number'
};

export const FARMER_DEVICE_TELEMETRY_ENUM = {
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  SIM_LATITUDE: 'sim_latitude',
  SIM_LONGITUDE: 'sim_longitude',
  TEMPERATURE_1: 'temperature-1',
  TEMPERATURE_2: 'temperature-2',
  TEMPERATURE_AMBIENT: 'temperature-ambient',
  HUMIDITY_1: 'humidity-1',
  HUMIDITY_AMBIENT: 'humidity-ambient',
  CROP_TYPE: 'crop_type',
  GPS_LOCATION: 'gps_location',
  SIM_LOCATION: 'sim_location'
};

export const MANAGER_DEVICE_ATTRIBUTE_ENUM = {
  ACTIVE: 'active',
  SUBSYS_CONFIG: 'subsysConfig',
  LAST_ACTIVITY: 'lastActivityTime',
  LAST_CONNECT: 'lastConnectTime',
  LAST_DISCONNECT: 'lastDisconnectTime',
  AGW_VERSION: 'agw_version',
  IP: 'ip'
};

export const MANAGER_DEVICE_TELEMETRY_ENUM = {
  MEMORY_AVAILABLE: 'memory_available',
  MEMORY_FREE: 'memory_free'
};

export const FARMER_DEVICE_TAB_NAVIGATION_ENUM = {
  SENSOR_SPEAR: 'SENSOR_SPEAR',
  GRAIN_ANALYZERS: 'GRAIN_ANALYZERS'
};

export const SENSOR_TYPES_ENUM = {
  SENSOR_1: 'SENSOR_1',
  SENSOR_2: 'SENSOR_2',
  SENSOR_AMBIENT: 'SENSOR_AMBIENT'
};
