// @flow
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { IntlShape, useIntl } from 'react-intl';
import { TbLocationItem } from 'types/Thingsboard.types';
import { TB_LOCATION_ITEM_TYPE_ENUM } from 'constants/ThingsboardConstants';
import { isDefined } from 'util/ObjectUtils';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

const createColumns = (intl: IntlShape): GridColDef[] => {
  return [
    { field: 'itemNo', headerName: '#', width: 60 },
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'app.datagrid.name' }),
      width: 400
    },
    {
      field: 'type',
      headerName: intl.formatMessage({ id: 'app.datagrid.type' }),
      width: 250
    },
    { field: 'customerName', headerName: intl.formatMessage({ id: 'app.datagrid.customer' }), width: 250 },
    {
      field: 'claimed',
      headerName: intl.formatMessage({ id: 'app.datagrid.claimed' }),
      width: 100,
      renderCell: (cellValues: GridRenderCellParams): React$Node => {
        const isClaimed = cellValues.row.claimed;
        const type = cellValues.row.type;
        let ResolvedIcon;
        if (type === TB_LOCATION_ITEM_TYPE_ENUM.SENSOR_SPEAR) {
          ResolvedIcon = isClaimed ? (
            <CheckCircleOutlinedIcon color="success" />
          ) : (
            <HighlightOffOutlinedIcon color="error" />
          );
        }
        return ResolvedIcon;
      }
    },
    { field: 'latitude', headerName: intl.formatMessage({ id: 'app.datagrid.latitude' }), width: 100 },
    { field: 'longitude', headerName: intl.formatMessage({ id: 'app.datagrid.longitude' }), width: 100 }
  ];
};

type Props = {
  items: Array<TbLocationItem>,
  gridElementsSettings: Object,
  searchedItem: TbLocationItem
};

const LocationItemsDataGrid = ({ items, gridElementsSettings, searchedItem }: Props): React$Node => {
  const intl = useIntl();

  let rows = items
    ?.filter((item: TbLocationItem) => gridElementsSettings[item.type])
    ?.map((item: TbLocationItem, index: number) => ({
      itemNo: index + 1,
      id: item.id.id,
      name: item.name,
      type: item.type,
      longitude: item.longitude,
      latitude: item.latitude,
      claimed: TB_LOCATION_ITEM_TYPE_ENUM.SENSOR_SPEAR === item.type ? item.claimed : null,
      customerName: item.customerName
    }));

  if (isDefined(searchedItem)) {
    rows = rows?.filter((item: TbLocationItem) => item.name === searchedItem.name);
  }

  return (
    <Box sx={{ height: '65vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={createColumns(intl)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100
            }
          }
        }}
        pageSizeOptions={[100]}
        slots={{
          toolbar: GridToolbar
        }}
        density={'compact'}
        disableColumnSelector
        disableColumnFilter
      />
    </Box>
  );
};

export default LocationItemsDataGrid;
