// @flow

import { Dispatch } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from 'store/slice/ApplicationSlice';
import { get } from 'api/RestApi';
import { AGROLOG_ANALYTICS_BASIC_AUTHORIZATION_CODE, REFRESH_CACHE } from 'constants/RestApiConstants';
import { handleError } from 'api/RestApiHelper';

export async function getAnalyticsBasicAuthorizationCode(dispatch: Dispatch): Promise {
  dispatch(startLoading());
  return get(AGROLOG_ANALYTICS_BASIC_AUTHORIZATION_CODE)
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}

export async function refreshCache(dispatch: Dispatch, cacheType: string): Promise {
  dispatch(startLoading());
  return get(REFRESH_CACHE, { cacheType })
    .catch((error) => handleError(error, dispatch))
    .finally(() => dispatch(stopLoading()));
}
